export const actionTypes = {
  TOGGLE_DIALOG: 'UI/TOGGLE_DIALOG',
  WINDOW_SIZE: 'UI/WINDOW_SIZE',
}

export function toggleDialog() {
  return {
    type: actionTypes.TOGGLE_DIALOG,
  }
}

export const getWindowSizeAction = (width) => ({
  type: actionTypes.WINDOW_SIZE,
  payload: width,
})
