/**
 * Base style for application. To rewrite any setting here simply add a matching
 * property in your theme file and it will be replaced with your style.
 */

import { redirectWithoutCache } from 'next-i18next/dist/commonjs/utils'
import finnFont from './fonts/finnFont'
import finnFontBold from './fonts/finnFontBold'

const fontFaces = [
  {
    fontFamily: 'Finntype',
    fontDisplay: 'swap',
    fontFormat: 'woff',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSrc: finnFont,
  },
  {
    fontFamily: 'Finntype',
    fontDisplay: 'swap',
    fontFormat: 'woff',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSrc: finnFontBold,
  },
]

const media = {
  sizes: {
    small: 620,
    medium: 770,
    large: 992,
  },
  settings: {
    small: { fontSize: '14px' },
  },
}

export default {
  // Brand colors
  brandColor: '#0062FA',
  linkColor: '#0062FA',
  linkColorHover: '#0062FA',
  brandFontColor: '#fff',

  secondaryColor: '#07BEFB',
  pageBGColor: '#F1F9FF',
  boxBGColor: '#F1F1F3',
  mapBGColor: '#f6f7f9',

  dangerColor: '#e70a0a',
  dangerColor60: 'rgba(231, 10, 10, 0.06)',
  routeInfoItemErrorBg: '#F1F1F3',

  // info popup
  infoPopupBackground: '#fff',
  infoPopupColor: '#474445',
  infoPopupCloseButtonColor: '#0062FA',

  // map
  mapClusterBackground: '#0062FA',
  mapClusterColor: '#fff',
  mapMenuBackground: '#fff',
  mapMenuBorder: '#E0E0E0',
  mapMenuItemBackground: '#f6f7f9',
  mapMenuItemColor: '#474445',
  mapMenuItemSelectedBackground: '#0062FA',
  mapMenuItemSelectedColor: '#fff',
  mapZoomColor: '#0062FA',
  mapMarkerBackground: '#0062FA',
  mapMarkerColor: '#fff',

  // conversion
  conversionBoxBackground: '#fef3c2',
  conversionBoxButtonColor: '#fff',
  conversionBoxButtonBackground: '#0062FA',

  // map conversion
  mapConversionBackground: '#0062FA',
  mapConversionColor: '#fff',

  // comparison widget
  comparisonWidgetBG: '#cdffec',
  comparisonWidgetBorder: '#7de8c3',
  comparisonWidgetLinkColor: '#0062FA',

  // comparison
  comparisonHeaderBorder: '#E0E0E0',
  comparisonHeaderBackground: '#fff',
  comparisonHeaderButtonBackground: '#f6f7f9',
  comparisonListOddBackground: '#F1F9FF',
  comparisonListBorderBackground: '#E0E0E0',

  // card list
  cardBGColor: '#fcfcfc',
  cardNavigationBackground: '#fff',
  cardNavigationIcon: '#0062FA',

  // header menu
  headerHeight: `${42 / 16}rem`,
  headerColor: '#fff',
  headerFontColor: '#323230',
  headerMenuBGColor: '#ffffff',
  headerMenuFontColor: '#474445',
  headerMenuHeading: '#8d8c92',
  headerMenuBorderColor: '#C8C7CC',
  headerMenuIconColor: '#07BEFB',
  headerMenuIconHoverColor: '#0062FA',

  // button
  buttonBackground: '#0062FA',
  buttonColor: '#fff',

  // dropdowns
  dropdownBackground: '##ffffff',
  dropdownColor: '#474445',
  dropdownBackgroundHover: '#f1f8ff',
  dropdownBorder: '#0063fb',
  dropdownColorHover: '#474445',

  // Bottom nav
  bottomNavBorderColor: '#c8c7cc',
  bottomNavIconColor: '#07BEFB',
  bottomNavIconHoverColor: '#0062FA',

  // General settings
  contentBGColor: '#fff',
  borderColor: '#E0E0E0',
  clearButtonBg: '#a1a1a1',
  white: '#fff',
  black: '#000',
  linkDecoration: 'none',
  midGray: '#737172',

  // inputs
  inputColor: '#5a5858',

  // ratings
  ratingBarBg: '#dfe4e8',
  ratingBarAvgBg: '#c3ccd9',
  ratingBarProgressBg: '#68e2b8',

  // Footer
  footerLinkColor: '#474445',
  footerLinkHoverColor: '#5a5858',

  // Font settings
  fontColor: '#474445',
  fontColorLight: '#5A5858',
  fontSize: '18px',
  fontFaces,
  fontFamily: '"Finntype", Arial, Helvetica, sans-serif',
  fontWeight: 400,
  fontWeightBold: 700,
  lineHeight: '25px',
  absoluteTextColor: '#474445',

  // Spacings
  spacing: '20px',
  spacingXLarge: '60px',
  spacingLarge: '40px',
  spacingSmall: '10px',
  spacingMicro: '5px',
  media,

  // travel time
  iconColor: '#fff',
  iconBackground: '#2761f1',
  flagIconColor: '#fff',
  flagIconBackground: '#e4e4e4',
  travelInfoItemBackground: '#ccffec',
  travelInfoItemNavBackground: '#98f4d2',

  // Headings style
  headings: {
    h1: {
      size: '32px',
      weight: 'bold',
    },
    h2: {
      size: '30px',
    },
  },
  // Chart styling
  pieChartColors: ['#224873', '#42A5F5', '#90CAF9', '#0277BD', '#E2EBFD'],
  barChartColors: {
    mainColor: '#83CDFF',
    comparisonColor: '#0062FA',
  },
  areaChartColors: {
    mainColor: '#83CDFF',
    comparisonColor: '#0062FA',
    labelColor: '#474445',
    fillOpacity: 0.6,
  },
  poiItemBorder: 'rgba(0, 0, 0, 0.12)',
  poiLineBorderColor: 'rgba(0, 0, 0, 0.12)',
  cardHeaderBorder: 'rgba(0, 0, 0, 0.12)',
  textChartBorder: 'rgba(0, 0, 0, 0.12)',
  barChartBorder: 'rgba(0, 0, 0, 0.12)',

  // Suncurves theming
  sunCurves: {
    spring: '#FC9700',
    winter: '#C93297',
    elevation: '#7bb02d',
  },

  // Schibsted
  loginEntryBackgroundColor: '#f1f8ff',

  poiIconColors: {
    210: '#4AD39B',
    211: '#4AD39B',
    220: '#4AD39B',
    221: '#4AD39B',
    222: '#4AD39B',
    223: '#4AD39B',
    230: '#4AD39B',
    235: '#4AD39B',
    240: '#4AD39B',
    241: '#4AD39B',
    250: '#4AD39B',
    260: '#4AD39B',
    270: '#00d332',
    280: '#00d332',
    310: '#BA68C8',
    330: '#BA68C8',
    350: '#BA68C8',
    351: '#BA68C8',
    352: '#BA68C8',
    353: '#BA68C8',
    354: '#BA68C8',
    355: '#BA68C8',
    356: '#BA68C8',
    357: '#BA68C8',
    358: '#BA68C8',
    401: '#FE705E',
    402: '#FE705E',
    500: '#FE705E',
    550: '#7abbfe',
    551: '#7abbfe',
    600: '#fe9bfa',
    default: '#0062FA',
  },
}
